import Vue from 'vue'
import Vuex from 'vuex'

import AppModule from './modules/app'
import FinanceModule from './modules/finance'

Vue.use(Vuex)

const Store = new Vuex.Store({
  modules: {
    App: AppModule,
    Finance: FinanceModule,
  },

  strict: true,
})

export default Store
