import { loadLanguageAsync } from '../i18n'

export const logMid = ({ to, next }) => {
  if (process.env.NODE_ENV == 'development') {
    console.log(`[>] ${to.name}`)
  }

  return next()
}

export const i18nMid = ({ next, store }) => {
  loadLanguageAsync('en')
    .then(next)
    .catch(err => {
      console.error(err)
      // next({ name: 'sign-in' })
    })
}