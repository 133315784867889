import Vue from 'vue'
import Router from 'vue-router'
import { i18nMid, logMid } from './middlewares'
import { middlewareGroup, MiddlewarePlugin } from './plugins/middleware'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      component: () => import('../components/UserLayout'),
      path: '/',
      redirect: { name: 'account' },
      children: middlewareGroup([logMid, i18nMid], [
        {
          component: () => import('../views/trade'),
          path: '/',
          name: 'trade',
        },
      ]),
    },
  ],
})

router.beforeEach((...args) => MiddlewarePlugin(...args, router))

export default router
