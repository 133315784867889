class M_TYPE {
  constructor(module, obj) {
    for (const key in obj) {
      this[key] = obj[key]
    }

    this._module_ = module
  }

  get MOD() {
    let res = {}
    for (let key in this) {
      if (key == 'MOD') continue
      res[key] = this._module_ + '/' + this[key]
    }

    return res
  }
}

export const APP = new M_TYPE('App', {
  TOGGLE_USER_SIDEBAR: 'TOGGLE_USER_SIDEBAR',
  TOGGLE_HIDE_BALANCES: 'TOGGLE_HIDE_BALANCES',
  UPDATE_PERIOD_CANDLES: 'UPDATE_PERIOD_CANDLES',
})

export const FINANCE = new M_TYPE('Finance', {
  INIT_TRADABLE_PAIRS: 'INIT_TRADABLE_PAIRS',
  INIT_AVAILABLE_PAIRS: 'INIT_AVAILABLE_PAIRS',
  INIT_CURRENCIES: 'INIT_CURRENCIES',
  INIT_FUTURES_PRICES: 'INIT_FUTURES_PRICES',
  TOGGLE_FAVORITE_PAIR: 'TOGGLE_FAVORITE_PAIR',
  UPDATE_SELECTED_PAIR: 'UPDATE_SELECTED_PAIR',
  INIT_MARKERS: 'INIT_MARKERS',
  INIT_BALANCE_STATS: 'INIT_BALANCE_STATS',
  DAY_PAIRS_STATS: 'DAY_PAIRS_STATS',
  TOGGLE_FAVORITE_PERIOD: 'TOGGLE_FAVORITE_PERIOD',
})
