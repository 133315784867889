import Store from '../../store'
import * as MUTATION_TYPES from '../../store/mutation-types'

export default {
  'day-statistic'({ list }) {
    Store.commit(MUTATION_TYPES.FINANCE.MOD.DAY_PAIRS_STATS, list)
  },

  'futures-prices'({ list }) {
    Store.commit(MUTATION_TYPES.FINANCE.MOD.INIT_FUTURES_PRICES, list)
  },

  'update-markers'({ list }) {
    Store.commit(MUTATION_TYPES.FINANCE.MOD.INIT_MARKERS, list)
  },

  'update-app-balance-stats'(data) {
    Store.commit(MUTATION_TYPES.FINANCE.MOD.INIT_BALANCE_STATS, data)
  },
}
