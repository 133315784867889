import * as MUTATION_TYPES from '../mutation-types'
import http from '../../http'

const LS_KEYS = {
  HIDE_BALANCES: 'hideBalances',
  SELECTED_PERIOD_CANDLES: 'selectedPeriodCandles',
}

// initial state
const state = {
  showUserSidebar: true,
  hideBalances: Boolean(localStorage.getItem(LS_KEYS.HIDE_BALANCES) || ''),
  selectedPeriodCandles:
    localStorage.getItem(LS_KEYS.SELECTED_PERIOD_CANDLES) ||
    process.env.VUE_APP_DEFAULT_PERIOD_CANDLES,
}

// getters
const getters = {}

// actions
const actions = {
  getBotTerminalMessages() {
    return new Promise((resolve, reject) => {
      http.api
        .get('/bot/terminal-messages')
        .then(({ data }) => {
          resolve(data)
        })
        .catch(reject)
    })
  },
}

// mutations
const mutations = {
  /**
   * Initial current active box in this module state
   * @param {Boolean} value
   */
  [MUTATION_TYPES.APP.TOGGLE_USER_SIDEBAR](state, value) {
    state.showUserSidebar = value
  },

  /**
   * Toggle view real balances of user wallets
   * @param {Boolean} value
   */
  [MUTATION_TYPES.APP.TOGGLE_HIDE_BALANCES](state, value) {
    state.hideBalances = value
    localStorage.setItem(LS_KEYS.HIDE_BALANCES, value ? '1' : '')
  },

  /**
   * Update selected period candles
   * @param {Boolean} string
   */
  [MUTATION_TYPES.APP.UPDATE_PERIOD_CANDLES](state, value) {
    state.selectedPeriodCandles = value
    localStorage.setItem(LS_KEYS.SELECTED_PERIOD_CANDLES, value)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
