import Vue from 'vue'

import { i18n } from './i18n'

import './filters'
import Notifications from 'vue-notification'

import VueLuxon from 'vue-luxon'

// import Cryptoicon from 'vue-cryptoicon'
// Import all icons
// import icon from 'vue-cryptoicon/src/icons'
// Cryptoicon.add(icon)

Vue.use(VueLuxon, {
  templates: {
    output: {
      zone: 'America/New_York',
    },
  },
})

import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

moment.tz.setDefault('America/New_York')

Vue.use(VueMoment, {
  moment,
})

import App from './components/App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import { BootstrapVueIcons } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

import router from './routes'
import store from './store'

import { socketMixin } from './sockets'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Notifications)
// Vue.use(Cryptoicon)

Vue.component('cryptoicon', () => import('./components/CryptoIcon.vue'))

Vue.component('UiPreloader', () => import('./components/Preloader.vue'))

Vue.directive('click-outside', {
  bind: function(el, binding, vnode) {
    setTimeout(() => {
      el.clickOutsideEvent = function(event) {
        if (!(el == event.target || el.contains(event.target))) {
          vnode.context[binding.expression](event)
        }
      }
      document.body.addEventListener('click', el.clickOutsideEvent)
    }, 100)
  },
  unbind: function(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

new Vue({
  render: h => h(App),
  router,
  store,
  i18n,

  mixins: [socketMixin],
}).$mount('#app')
