import _ from 'lodash'
import Store from '../../store'

function middlewarePipeline(context, middleware, index) {
  const nextMiddleware = middleware[index]

  if (!nextMiddleware) return context.next

  return () => {
    const nextPipeline = middlewarePipeline(context, middleware, index + 1)
    nextMiddleware({ ...context, next: nextPipeline })
  }
}

export const MiddlewarePlugin = (to, from, next, router) => {
  if (!to.meta.middleware) return next()

  const middleware = to.meta.middleware

  const context = {
    to,
    from,
    next,
    store: Store,
    router,
  }

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  })
}

export const middlewareGroup = (middlewareList, routes) => {
  middlewareList = Array.isArray(middlewareList)
    ? middlewareList
    : [middlewareList]

  let setMid = (route, list) => {
    _.set(
      route,
      'meta.middleware',
      _.concat(list, _.get(route, 'meta.middleware', []))
    )

    if (route.children)
      for (let i = 0; i < route.children.length; i++)
        setMid(route.children[i], route.meta.middleware)

    return route
  }

  return routes.map(e => setMid(e, middlewareList))
}
