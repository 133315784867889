import Vue from 'vue'
import VueSocketIO from 'vue-socket.io'

import { io } from 'socket.io-client'

import GlobalSockets from './groups/global'

Vue.use(
  new VueSocketIO({
    debug: false,
    connection: io(process.env.VUE_APP_SOCKET_CONNECTION),
    // vuex: {
    //     store,
    //     actionPrefix: 'SOCKET_',
    //     mutationPrefix: 'SOCKET_'
    // },
    options: {}, //Optional options
  })
)

export const socketMixin = {
  sockets: {
    ...GlobalSockets,
  },
}
